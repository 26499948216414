<template lang="pug">
li.listClass(v-if="!hasVideo || getActiveBreakoutSelf")
  .avatar.speaker.reaction(:class="[reaction, activeSpeaker]")
    Avatar(:firstName="user.firstName", :lastName="user.lastName", :securityId="user.securityId")
  .name
    p {{ user.firstName }} {{ user.lastName }}
  .status
    i.icon-edit-3(v-if="isAnnotating", style="color: #03f63b")
    i(v-show="showPublishVideo", :class="hasVideoIcon", @click="publishVideo")
    i(v-show="stream", :class="hasAudioIcon", @click="publishAudio")
    .attendee-icon(v-if="!$auth.ADMIN && !isSelf", :data-name="`${dataName}-overlay-${index}`")
      .span.icon-button(
        :id="`${dataName}${index}`",
        @click="onMessageParticipant",
        :data-name="`${dataName}-menu-${index}`"
      )
        i.icon-message-circle
    .attendee-icon(v-if="$auth.ADMIN && !isAdmin", :data-name="`${dataName}-overlay-${index}`")
      .span.icon-button(
        :id="`${dataName}${index}`",
        @click="onMenuClick(`#${dataName}${index}`)",
        :data-name="`${dataName}-menu-${index}`"
      )
        Icon(value="more-horizontal", color="white")
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  Group,
  Mute,
  MediaUpdateType,
  SelectUserTab,
  SetActiveId,
  SidebarUserTabs,
  UserModel,
  PrivateChatStart,
} from "@/types";
import { StatusOptions } from "@/types/status";
import { Stream } from "@opentok/client";
import Avatar from "../Avatar.vue";
import { ReactionUserModel } from "@/types/reaction";
import authorization from "@/auth/authorization";
import { EventContentModel } from "@cruciallearning/puddle";
import { chatGroupName } from "@/utils";
import { Icon } from "@cruciallearning/puddle/components";
@Component({
  components: { Avatar, Icon },
  computed: {
    ...mapState("VonageModule", ["publisher", "subscribers"]),
    ...mapState("EventModule", ["event"]),
    ...mapState(["trainingEventId", "activeSpeakerId"]),
    ...mapState("AnnotationsModule", ["annotatorId"]),
    ...mapState("UsersModule", ["statuses"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters("UsersModule", ["getById", "getSelf"]),
  },
  methods: {
    ...mapMutations("ChatModule", ["setActiveId"]),
    ...mapActions(["admitToClassroom"]),
    ...mapActions("ChatModule", ["privateChatStart"]),
    ...mapActions("UsersModule", ["mute"]),
    ...mapActions("SidebarModule", ["selectUserTab"]),
  },
})
export default class Attendee extends Vue {
  @Prop({ required: true }) readonly dataName!: string;
  @Prop({ required: true }) readonly index!: number;
  @Prop({ required: false }) readonly user!: UserModel;

  private readonly publisher!: OT.Publisher | null;
  private readonly subscribers!: OT.Subscriber[];

  private readonly getSelf!: UserModel;
  private readonly trainingEventId!: string;
  private readonly statuses!: ReactionUserModel[];
  private readonly annotatorId!: string;
  private readonly privateChatStart!: PrivateChatStart;
  private readonly setActiveId!: SetActiveId;
  private readonly selectUserTab!: SelectUserTab;
  private readonly mute!: Mute;
  private readonly event!: EventContentModel;
  private readonly getActiveBreakoutSelf!: Group | undefined;
  private readonly activeSpeakerId!: string;

  get stream(): Stream | undefined {
    if (this.user.securityId == this.getSelf.securityId && this.publisher) {
      return this.publisher.stream;
    }
    if (this.subscribers) {
      return this.subscribers.find((e) => {
        if (e.stream?.connection.data) {
          const securityId = JSON.parse(e.stream?.connection.data).securityId;
          return this.user.securityId === securityId;
        }
        return false;
      })?.stream;
    }
    return undefined;
  }

  get hasAudioIcon(): string {
    const pointerEvents = this.isSelf || (this.stream?.hasAudio && this.$auth.ADMIN) ? "" : " pointer-none";
    return `${this.stream?.hasAudio ? "icon-mic on" : "icon-mic-off off"}${pointerEvents}`;
  }

  get hasVideoIcon(): string {
    const pointerEvents = this.isSelf ? "" : " pointer-none";
    return `${this.stream?.hasVideo ? "icon-video on" : "icon-video-off off"}${pointerEvents}`;
  }

  get hasVideo(): boolean {
    if (this.stream) return this.stream?.hasVideo;
    return false;
  }

  get isAdmin(): boolean {
    return (
      authorization.isManager(this.user.securityId, this.event) ||
      authorization.isObserver(this.user.securityId, this.event)
    );
  }

  get showPublishVideo(): boolean | Group | undefined {
    return (this.$auth.ADMIN && this.isAdmin && this.stream != undefined) || this.getActiveBreakoutSelf;
  }

  get isSelf(): boolean {
    return this.user.securityId === authorization.mySecurityId;
  }

  get isAnnotating(): boolean {
    return this.user.securityId === this.annotatorId;
  }

  get reaction(): string {
    if (this.statuses) {
      const status = this.statuses.find((e) => e.securityId === this.user.securityId);
      if (status) {
        const reactionModel = StatusOptions.find((item) => status.status === item.display);
        if (reactionModel) {
          return reactionModel.cssClass;
        }
      }
    }
    return "";
  }

  get activeSpeaker(): string {
    if (this.user.securityId === this.activeSpeakerId && !this.getActiveBreakoutSelf) {
      return "active-speaker-audio";
    }
    return "";
  }

  publishAudio(): void {
    if (!this.isSelf && this.$auth.ADMIN) {
      if (this.stream?.hasAudio) this.mute({ type: MediaUpdateType.USER, securityId: this.user.securityId });
    } else {
      if (this.publisher) this.publisher.publishAudio(!this.stream?.hasAudio);
    }
  }

  publishVideo(): void {
    if (this.publisher) this.publisher.publishVideo(!this.stream?.hasVideo);
  }

  onMenuClick(id: string): void {
    this.$emit("clickMenu", { id, user: this.user });
  }

  onMessageParticipant(): void {
    if (!this.$auth.authUser.id) return;
    const groupName = chatGroupName(this.$auth.authUser.id, this.user.securityId);
    const activeId = `${this.trainingEventId}.${groupName}`;
    if (this.getSelf) {
      this.setActiveId(activeId);
      this.selectUserTab(SidebarUserTabs.CHAT);
      this.privateChatStart(this.user.securityId);
    }
  }
}
</script>
