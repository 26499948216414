import store from "@/store";
import { ChatType } from "@/types";
import { LocalStorageFields } from "@/types/base";
import { chatGroupName } from ".";
import { getInstance } from "@cruciallearning/puddle/auth";

const delim = "--";
const GroupChatUtil = {
  cacheVal(): string {
    const hidden = store.state.ChatModule?.hiddenList || [];
    const isHidden = (groupId: string): boolean => hidden.findIndex((e) => e === groupId) != -1;
    const isUserToUser = (groupId: string): boolean => groupId.split(".").length == 3;
    const canClose = (groupId: string): boolean => groupId != ChatType.MAIN && groupId != ChatType.LOBBY;
    return (
      store.state.ChatModule?.groupList
        .map((e) => e.groupId)
        .filter((e) => !isHidden(e) && canClose(e) && isUserToUser(e))
        .join(delim) || ""
    );
  },
  saveCache(): void {
    sessionStorage.setItem(LocalStorageFields.OPEN_GROUPS, this.cacheVal());
  },
  readCache(): string[] {
    const stored = sessionStorage.getItem(LocalStorageFields.OPEN_GROUPS);
    if (!stored) return [];
    return stored.split(delim);
  },

  openGroup(groupId: string): void {
    const users = store.state.UsersModule?.users;
    const getSecurityId = (name: string): string | undefined => {
      const user = users?.find((e) => name === `${e.firstName} ${e.lastName}`);
      if (user) return user.securityId;
    };
    const parts = groupId.split(".");
    const friendId = parts[1] == getInstance().authUser.id ? parts[2] : parts[1];
    store.dispatch("ChatModule/privateChatStart", friendId);
  },

  openCached(): void {
    const openGroups = GroupChatUtil.readCache();
    const users = store.state.UsersModule?.users;
    if (users && openGroups) {
      openGroups.forEach((groupId) => this.openGroup(groupId));
    }
  },
  getUnread(): string[] {
    let stored = sessionStorage.getItem(LocalStorageFields.UNREAD_GROUPS);
    if (!stored) stored = "";
    return stored.split(delim);
  },
  addUnread(group: string): void {
    const groups = this.getUnread();
    if (groups.findIndex((e) => e === group) == -1) {
      groups.push(group);
    }
    const unreadCache = groups.join(delim);
    sessionStorage.setItem(LocalStorageFields.UNREAD_GROUPS, unreadCache);
  },
  markAsRead(group: string): void {
    const groups = this.getUnread();
    const idx = groups.findIndex((e) => e === group);
    if (idx != -1) {
      groups.splice(idx, 1);
      const unreadCache = groups.join(delim);
      sessionStorage.setItem(LocalStorageFields.UNREAD_GROUPS, unreadCache);
    }
  },
  isUnread(group: string): boolean {
    return this.getUnread().findIndex((e) => e === group) != -1;
  },
};
export default GroupChatUtil;
