import { ActionContext, Module } from "vuex";
import {
  ChatGroupModel,
  ChatModel,
  ChatState,
  ChatStatusModel,
  ChatType,
  PinnedChatModel,
  RootState,
  SidebarUserTabs,
  UserModel,
} from "@/types";
import { emojis } from "./emoji";
import { sockets } from "@/services/socket_api";
import store from "@/store";
import { format } from "date-fns";
import authorization from "@/auth/authorization";
import { getInstance } from "@cruciallearning/puddle/auth";
import GroupChatUtil from "@/utils/GroupChatUtil";
import chatUtil from "@/utils/chatUtil";
import { CHAT_TIMEOUT } from "./constants";

const ChatModule: Module<ChatState, RootState> = {
  namespaced: true,
  state(): ChatState {
    return {
      activeId: ChatType.MAIN,
      activeTyper: "",
      activeTyperTimeout: void 0,
      editMessage: "",
      editBackstageMessage: "",
      backstage: [],
      backstageIsNew: false,
      backstageTyper: "",
      backstageTyperTimeout: void 0,
      group: [],
      groupList: [
        {
          groupId: ChatType.MAIN,
          groupName: "General.General",
          participants: [],
          isNew: false,
        },
        {
          groupId: ChatType.LOBBY,
          groupName: "Lobby.Lobby",
          participants: [],
          isNew: false,
        },
      ],
      hiddenList: [],
      main: [],
      lobby: [],
      status: [],
      pinned: [],
      emojis: emojis,
      pendingNew: [],
    };
  },
  getters: {
    getChatByType:
      (state: ChatState): ((type: ChatType) => ChatModel[]) =>
      (type: ChatType): ChatModel[] => {
        if (type === ChatType.GROUP) {
          return state[type].filter((item) => item.type === type && item.groupId === state.activeId);
        }
        return state[type].filter((item) => item.type === type);
      },
    getEmojis(state: ChatState): Array<string> {
      return state.emojis;
    },
    getGroupExists:
      (state: ChatState): ((groupId: string) => boolean) =>
      (groupId: string): boolean => {
        return state.groupList.findIndex((item) => item.groupId === groupId) !== -1;
      },
    getStatusByType:
      (state: ChatState): ((type: ChatType) => ChatStatusModel | undefined) =>
      (type: ChatType): ChatStatusModel | undefined => {
        return state.status.find((item: ChatStatusModel): boolean => item.type === type);
      },
    getIsNew: (state: ChatState): boolean => {
      let isNew = false;
      state.groupList.forEach((item) => {
        if (item.isNew !== undefined) {
          isNew = isNew || item.isNew;
        }
      });
      return isNew;
    },
    getIsBackstageNew: (state: ChatState): boolean => {
      return state.backstageIsNew !== undefined && state.backstageIsNew;
    },
  },
  mutations: {
    setChatState(state: ChatState, changes: Partial<ChatState>): void {
      Object.assign(state, changes);
    },
    setActiveId(state: ChatState, id: string): void {
      if (state.activeId !== id) state.activeId = id;
      const group = state.groupList.find((item) => item.groupId === id);
      if (group) {
        group.isNew = false;
        GroupChatUtil.markAsRead(id);
      }
    },
    setEditMessage(state: ChatState, val: string): void {
      state.editMessage = val;
    },
    setEditBackstageMessage(state: ChatState, val: string): void {
      state.editBackstageMessage = val;
    },
    setMessage(state: ChatState, message: ChatModel): void {
      if (state[message.type].length >= 100) {
        state[message.type].splice(state[message.type].length - 1);
      }
      state[message.type].unshift(message);

      if (message.type === ChatType.BACKSTAGE) {
        state.backstageIsNew = true;
        return;
      }
      const group = state.groupList.find((item) => item.groupId === message.groupId);
      if (!group) {
        if (message.groupId) {
          state.pendingNew.push(message.groupId);
          GroupChatUtil.openGroup(message.groupId);
        }
      } else {
        store.commit("ChatModule/groupShow", { groupId: group.groupId });
        if (
          message.type == ChatType.MAIN ||
          (message.type != ChatType.LOBBY && message.groupId && message.groupId !== state.activeId)
        ) {
          group.isNew = !message.self;
          if (message.groupId) GroupChatUtil.addUnread(message.groupId);
        }
      }
    },
    setMainMessages(state: ChatState, messages: ChatModel[]) {
      if (messages.length > 0) {
        state[ChatType.MAIN].push(...messages);
        state.groupList[0].isNew = true;
      }
    },
    setBackStageMessages(state: ChatState, messages: ChatModel[]) {
      state[ChatType.BACKSTAGE].push(...messages);
    },
    setGroupMessages(state: ChatState, messages: ChatModel[]) {
      if (messages) {
        const ids = state[ChatType.GROUP].map((e) => e.id);
        messages.forEach((message) => {
          if (ids.findIndex((e) => e === message.id) === -1) {
            state[ChatType.GROUP].push(message);
          }
        });
      }
    },
    setLobbyMessages(state: ChatState, messages: ChatModel[]) {
      state[ChatType.LOBBY].push(...messages);
    },
    setGroup(state: ChatState, group: ChatGroupModel): void {
      group.isNew = false;
      const pendingIdx = state.pendingNew.findIndex((e) => e === group.groupId);
      if (pendingIdx != -1) {
        group.isNew = true;
        state.pendingNew.splice(pendingIdx, 1);
        GroupChatUtil.addUnread(group.groupId);
      }
      if (GroupChatUtil.isUnread(group.groupId)) {
        group.isNew = true;
      }
      const index = state.groupList.findIndex((item) => item.groupId === group.groupId);
      if (index >= 0) {
        const existing = state.groupList[index];
        if (existing && existing.participants && group.participants && group.inGroup != false) {
          const message = {
            id: Math.random().toString(),
            groupId: group.groupId,
            groupName: group.groupName,
            firstName: "",
            lastName: "",
            message: "",
            self: false,
            time: format(new Date(), "h:mm aa"),
            type: ChatType.GROUP,
          };
          group.participants.forEach((participant) => {
            if (existing.participants && existing.participants.find((e) => e.id == participant.id) === undefined) {
              console.log(participant.firstName + " " + participant.lastName + " has joined chat " + group.groupId);
              message.firstName = participant.firstName;
              message.lastName = participant.lastName;
              message.message = participant.firstName + " " + participant.lastName + " has joined the chat";
              state[ChatType.GROUP].unshift(message);
            }
          });
          const isHidden = state.hiddenList.findIndex((e) => e === existing.groupId) != -1;
          if (!isHidden) {
            existing.participants.forEach((participant) => {
              if (group.participants && group.participants.find((e) => e.id == participant.id) === undefined) {
                console.log(participant.firstName + " " + participant.lastName + " has left chat " + group.groupId);
                message.firstName = participant.firstName;
                message.lastName = participant.lastName;
                message.message = participant.firstName + " " + participant.lastName + " has left the chat";
                state[ChatType.GROUP].unshift(message);
              }
            });
          }
        }

        if (group.groupId == state.activeId && !group.participants) state.activeId = ChatType.MAIN;
        state.groupList.splice(index, 1);
        if (group.participants == null || group.participants.length == 0) {
          state[ChatType.GROUP] = state[ChatType.GROUP].filter((e) => e.groupId != group.groupId);
        }
      }
      if (group.participants && group.participants.length > 0 && group.inGroup != false) {
        state.groupList.push(group);
      }

      // Show the group if hidden
      const hiddenIndex = state.hiddenList.findIndex((item) => item == group.groupId);
      if (hiddenIndex >= 0) {
        state.hiddenList.splice(hiddenIndex, 1);
      }
    },
    setStatus(state: ChatState, status: ChatStatusModel): void {
      const statusItem: ChatStatusModel | undefined = state.status.find(
        (item: ChatStatusModel): boolean => item.type === status.type
      );
      if (statusItem) statusItem.text = status.text;
      else state.status.push(status);
    },
    resetIsBackstageNew(state: ChatState): void {
      state.backstageIsNew = false;
    },
    deleteMessage(state: ChatState, payload: { messageId: string; type: ChatType }): void {
      const idx = state[payload.type].map((message) => message.id).indexOf(payload.messageId);
      if (idx > -1) {
        state[payload.type].splice(idx, 1);
      }
    },
    setPinnedMessages(state: ChatState, payload: { pinned: PinnedChatModel[] }): void {
      state.pinned = payload.pinned.map((e) => {
        const encodedMessage = chatUtil.formatMessage(e.content);
        return {
          ...e,
          time: format(new Date(`${e.sent}`), "h:mm aa"),
          message: encodedMessage?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        };
      });
    },
    addPinnedMessage(state: ChatState, message: PinnedChatModel): void {
      const idx = state.pinned.findIndex((item) => item.id === message.id);
      if (idx == -1) state.pinned.push({ ...message, time: format(new Date(`${message.sent}`), "h:mm aa") });
    },
    removePinnedMessage(state: ChatState, payload: { messageId: string }): void {
      const idx = state.pinned.findIndex((item) => item.sourceId === payload.messageId);
      if (idx !== -1) state.pinned.splice(idx, 1);
    },
    groupHide(state: ChatState, payload: { groupId: string }): void {
      const hiddenIndex = state.hiddenList.findIndex((item) => item == payload.groupId);
      if (hiddenIndex < 0) {
        const group = state.groupList.find((item) => item.groupId === payload.groupId);
        if (group) {
          state.hiddenList.push(group.groupId);
        }
      }
    },
    groupShow(state: ChatState, payload: { groupId: string }): void {
      const hiddenIndex = state.hiddenList.findIndex((item) => item == payload.groupId);
      if (hiddenIndex >= 0) {
        state.hiddenList.splice(hiddenIndex, 1);
      }
    },
  },
  actions: {
    addOrUpdateStatus({ commit }: ActionContext<ChatState, RootState>, text: string): void {
      const status: ChatStatusModel = { type: ChatType.MAIN, text };
      commit("setStatus", status);
    },
    groupAdd(_, payload: { groupId: string; participantIds: string[] }): void {
      sockets.chat.groupAdd(payload.groupId, payload.participantIds);
    },
    groupRemoveAll({ state, rootGetters }): void {
      const securityId = rootGetters["UsersModule/getSelf"]?.securityId;
      if (securityId && state.groupList.length > 1) {
        for (let i = 1, iN = state.groupList.length; i < iN; i++) {
          sockets.chat.groupRemove(state.groupList[i].groupId, [securityId]);
        }
      }
    },
    groupRemove(_, payload: { groupName: string; participantIds: string[] }): void {
      sockets.chat.groupRemove(payload.groupName, payload.participantIds);
    },

    privateChatStart(_, userId: string): void {
      sockets.chat.privateChatStartSend(userId);
    },
    groupStop(_, groupId: string): void {
      sockets.chat.groupStopSend(groupId);
    },
    sendMessage(
      { state, rootGetters }: ActionContext<ChatState, RootState>,
      payload: { type: ChatType; text: string }
    ): void {
      const isRestarting: boolean = rootGetters["EventModule/isRestarting"];
      const sessionId = isRestarting
        ? rootGetters["EventModule/restartSessionNumber"]
        : rootGetters["EventModule/sessionNumber"];
      switch (payload.type) {
        case ChatType.MAIN:
          sockets.chat.send(payload.text, sessionId);
          break;
        case ChatType.BACKSTAGE:
          sockets.chat.sendBackstage(payload.text, sessionId);
          break;
        case ChatType.LOBBY:
          sockets.chat.sendLobby(payload.text, sessionId);
          break;
        case ChatType.GROUP:
          if (state.activeId) {
            sockets.chat.sendGroup(state.activeId, payload.text, sessionId);
          }
          break;
      }
    },
    deleteMessage(_, payload: { type: ChatType; messageId: string }): void {
      switch (payload.type) {
        case ChatType.MAIN:
          sockets.chat.deleteMain(payload.messageId);
          break;
        case ChatType.LOBBY:
          sockets.chat.deleteLobby(payload.messageId);
          break;
      }
    },
    pinMessage({ state, dispatch }, payload: { type: ChatType; messageId: string }): void {
      if (payload.type === ChatType.MAIN) {
        if (state.pinned.length >= 3) {
          const oldest = state.pinned[0];
          dispatch("unpinMessage", { type: payload.type, messageId: oldest.sourceId });
        }
        sockets.chat.pinMain(payload.messageId);
      }
    },
    unpinMessage(_, payload: { type: ChatType; messageId: string }): void {
      if (payload.type === ChatType.MAIN) {
        sockets.chat.unpinMain(payload.messageId);
      }
    },
    messageParticipant({ rootGetters, rootState, dispatch, commit }, securityId: string): void {
      const userId = getInstance().authUser.id;
      if (!userId) return;
      const user = rootGetters["UsersModule/getById"](securityId);
      const recepientId = user.securityId;
      const groupName = userId > recepientId ? `${userId}.${recepientId}` : `${recepientId}.${userId}`;
      const trainingEventId = (rootState as RootState).trainingEventId;
      const activeId = `${trainingEventId}.${groupName}`;
      const self: UserModel | undefined = rootGetters["UsersModule/getSelf"];
      if (self) {
        commit("setActiveId", activeId);
        dispatch("SidebarModule/selectUserTab", SidebarUserTabs.CHAT, { root: true });
        dispatch("privateChatStart", user.securityId);
      }
    },
    updateTypers({ commit, state }, data: { senderId: string; group: string }): void {
      if (data.senderId === getInstance().authUser.id) return;
      if (data.group === ChatType.BACKSTAGE) {
        clearInterval(state.backstageTyperTimeout);
        commit("setChatState", {
          backstageTyper: data.senderId,
          backstageTyperTimeout: setTimeout(() => {
            commit("setChatState", { backstageTyper: "", backstageTyperTimeout: void 0 });
          }, CHAT_TIMEOUT),
        });
      } else {
        if (data.group === state.activeId) {
          clearInterval(state.activeTyperTimeout);
          commit("setChatState", {
            activeTyper: data.senderId,
            activeTyperTimeout: setTimeout(() => {
              commit("setChatState", { activeTyper: "", activeTyperTimeout: void 0 });
            }, CHAT_TIMEOUT),
          });
        }
      }
    },
  },
};
export default ChatModule;
