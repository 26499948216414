<template lang="pug">
.chat.expandable
  .sidebar-subhead(v-if="!waitingRoom")
    ul#chat-tabs.chat-tabs.list-unstyled
      li(
        v-for="item in filteredGroupList",
        v-if="!hiddenList.includes(item.groupId)",
        :class="{ active: item.groupId === activeId, 'has-new': item.isNew }",
        @click.stop="onChatSelect(item.groupId)"
      ) {{ parseGroupName(item) }}
        .close(v-if="allowClose(item)", @click.stop="onGroupHide(item.groupId)")
          i.icon-x 
  hr
  Resize(target="chat-tabs")
  Chat(:type="chatType", :waitingRoom="waitingRoom")
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import Chat from "@/components/Chat/BaseChat.vue";
import { ChatGroupModel, ChatType, GroupHide, SetActiveId, UserModel } from "@/types";
import GroupChatUtil from "@/utils/GroupChatUtil";
import Resize from "@/components/Sidebar/HorizontalResize.vue";
@Component({
  components: { Chat, Resize },
  computed: {
    ...mapState("ChatModule", ["activeId", "groupList", "hiddenList", "main"]),
  },
  methods: {
    ...mapMutations("ChatModule", ["groupHide", "setActiveId"]),
    ...mapActions("ChatModule", ["sendMessage"]),
  },
})
export default class BaseChatList extends Vue {
  @Prop({ required: false, default: false }) waitingRoom!: boolean;
  private readonly activeId!: string;
  private readonly groupHide!: GroupHide;
  private readonly setActiveId!: SetActiveId;
  private readonly groupList!: ChatGroupModel[];
  private readonly hiddenList!: string[];

  mounted(): void {
    this.setActiveId(this.activeId);
  }
  get chatType(): ChatType {
    if (this.activeId === ChatType.MAIN) return ChatType.MAIN;
    if (this.activeId === ChatType.LOBBY) return ChatType.LOBBY;
    else return ChatType.GROUP;
  }

  get filteredGroupList(): ChatGroupModel[] {
    if (this.waitingRoom) {
      return this.groupList.filter((e) => e.groupId === ChatType.LOBBY);
    } else {
      if (this.$auth.ADMIN) {
        return this.groupList;
      } else {
        return this.groupList.filter((e) => e.groupId !== ChatType.LOBBY);
      }
    }
  }

  allowClose(group: ChatGroupModel): boolean {
    return group.groupId !== ChatType.MAIN && group.groupId !== ChatType.LOBBY && group.breakout !== true;
  }

  parseGroupName(group: ChatGroupModel): string {
    const components = group.groupName.split(".");
    const idx = components.findIndex((e) => e == this.$auth.authUser.id);
    if (idx == -1) return components[0];
    const recipient = group.participants?.filter((e) => e.id != this.$auth.authUser.id);
    if (recipient && recipient.length == 1) {
      return `${recipient[0].firstName} ${recipient[0].lastName}`;
    }
    return components[0];
  }

  onChatSelect(activeId: string): void {
    if (this.activeId === activeId) return;
    this.setActiveId(activeId);
  }

  onGroupHide(groupId: string): void {
    this.setActiveId(ChatType.MAIN);
    this.groupHide({ groupId: groupId });
  }

  @Watch("filteredGroupList")
  @Watch("hiddenList")
  groupsChange(): void {
    GroupChatUtil.saveCache();
  }
}
</script>
