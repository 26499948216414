import config from "@/config";

const Routes = {
  //---> API Routes
  CONTENT: "/locales/%language%.content.json",
  LOGIN: "/locales/shared/user.json",
  DOWNLOADS: `${config.backendUrl}/downloads`,
  TRAINING_EVENT_CLOSE: "event.close",
  SOCKET: config.socketUrl,
  AUDITRON: `${config.backendUrl}/audit`,
  EVENT: `${config.backendUrl}/learningEvent`,
  COURSE: `${config.cdnUrl}/delivery`,
  ICON: `${config.cdnUrl}/icon`,
  //--> WebSocket Routes
  ANNOTATOR: "annotator",
  ANNOTATOR_CLAIM: "annotator.claim",
  ANNOTATOR_STATUS: "annotator.status",
  ANNOTATOR_WAIVE: "annotator.waive",
  ANNOTATOR_COLOR: "annotator.color",
  BREAKOUT: "breakout",
  BREAKOUT_ADD: "breakout.add",
  BREAKOUT_DETAILS: "breakout.details",
  BREAKOUT_DUPLICATE: "breakout.duplicate",
  BREAKOUT_GROUP_ADD: "breakout.group.add",
  BREAKOUT_GROUP_DETAILS: "breakout.group.details",
  BREAKOUT_GROUP_REMOVE: "breakout.group.remove",
  BREAKOUT_GROUP_UPDATE: "breakout.group.update",
  BREAKOUT_LEAVE: "breakout.group.leave",
  BREAKOUT_REMOVE: "breakout.remove",
  BREAKOUT_START: "breakout.start",
  BREAKOUT_STOP: "breakout.stop",
  BREAKOUT_UPDATE: "breakout.update",
  BREAKOUT_USER_ADD: "breakout.user.add",
  BREAKOUT_USER_REMOVE: "breakout.user.remove",
  BREAKOUT_TOKEN: "breakout.token",
  CHAT_BACKSTAGE_SEND: "chat.backstage.new",
  CHAT_GROUP_ADD: "chat.group.add",
  CHAT_GROUP_REMOVE: "chat.group.remove",
  CHAT_GROUP_STOP: "chat.group.stop",
  CHAT_GROUP_SEND: "chat.group.new",
  CHAT_GROUP_START: "chat.group.start",
  CHAT_DM_START: "chat.group.dm_start",
  CHAT_MAIN_SEND: "chat.main.new",
  CHAT_MAIN_DELETE: "chat.main.delete",
  CHAT_MAIN_PIN: "chat.main.pin",
  CHAT_MAIN_UNPIN: "chat.main.unpin",
  CHAT_MAIN_LOG: "chat.main.log",
  CHAT_LOBBY_SEND: "chat.lobby.new",
  CHAT_LOBBY_DELETE: "chat.lobby.delete",
  CLASSROOM_ADMIT: "classroom.admit",
  CLASSROOM_JOIN: "classroom.join",
  CLASSROOM_LEAVE: "classroom.leave",
  CLASSROOM_START: "classroom.start",
  CLASSROOM_RESTART: "classroom.restart",
  CLASSROOM_STATUS: "classroom.status",
  CLASSROOM_STOP: "classroom.stop",
  CLASSROOM_REMOVE_ALL: "classroom.remove_all",
  GENERAL: "general",
  PING: "ping",
  POLL_CREATE: "poll.create",
  POLL_CLOSE: "poll.close",
  POLL_ANSWER: "poll.answer",
  POLL_STATUS: "poll.status",
  REACTION_UPDATE: "reaction.update",
  REACTION_CLEAR: "reaction.clear",
  REACTION_CLEAR_USER: "reaction.clear.user",
  REMOTE_LOGGER: "logger.message",
  SLIDE_GOTO: "slide.goto",
  SLIDE_INFO: "slide.info",
  SLIDE_MODEL: "slide.model",
  SLIDE_MODEL_ALT: "slide.model.alt",
  TIMER_START: "timer.start",
  TIMER_PAUSE: "timer.pause",
  TIMER_RESUME: "timer.resume",
  TIMER_STOP: "timer.stop",
  TIMER_RESET: "timer.reset",
  TIMER_MUTE: "timer.mute",
  TIMER_SOUND: "timer.sound",
  VONAGE_TOKEN: "vonage.token",
  VONAGE_STREAM_ID: "vonage.stream.id",
  VIDEO_OVERLAY: "video.overlay",
  VIDEO_PLAY: "video.play",
  VIDEO_PAUSE: "video.pause",
  VIDEO_SCRUB: "video.scrub",
  VIDEO_INFO: "video.info",
  TEST_CLASSROOM_EMPTY: "test.classroom.empty",
  TEST_CLASSROOM_FILL: "test.classroom.fill",
};
export default Routes;
